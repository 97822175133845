
.content{
  transition:1000ms ease all;
  width:100%;
  height:200px;
}

.content.state-0{
  background:#111;
}
.content.state-1{
  background: rgb(221, 130, 77);
}
.content.state-2{
  background: rgb(77, 221, 202);
}
.content.state-3{
  background: rgb(106, 221, 77);
}